import React from 'react';
import { useRouter } from 'next/router';
import { I18nProvider } from '@lingui/react';
import { setupI18n } from '@lingui/core';
import * as plurals from 'make-plural/plurals';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/node';
import SimpleReactLightbox from 'simple-react-lightbox';

import { config } from 'utils/config';
import { UIProvider } from 'utils/ui';

import { PageTransition } from 'components/transition/PageTransition';

import 'styles/base.scss';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  enabled: process.env.NODE_ENV === 'production',
  ignoreErrors: [
    // GSAP fail
    'Cannot tween a null target.',

    // We dont want to know if anyone tries to iframe the page
    'Blocked a frame with origin',

    // Ignore when we throw to cancel Nextjs route navigation in register flow
    'routeChange aborted',

    // https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'TypeError: Cancelled',
    'TypeError: cancelled',

    // Firefox addons ?
    'can\'t redefine non-configurable property "userAgent"',

    // Not sure
    "Cannot read property 'postMessage' of null",

    // jQuery
    '$ is not defined',

    // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    'Non-Error promise rejection captured',

    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    'ResizeObserver loop limit exceeded',
  ],

  // Handle recaptcha related errors
  beforeSend(event, hint) {
    if (hint?.originalException === 'Timeout') return null;
    return event;
  },
});

export default function MyApp({ Component, pageProps }) {
  const router = useRouter();
  let locale =
    process.env.PRISMIC_LANGUAGES.split(',')[router.locales.indexOf(router.locale)]?.substr(0, 2) ||
    router.defaultLocale;

  const i18nGlobal = React.useMemo(() => {
    const i18n = setupI18n({
      locale,
      localeData: {
        [locale]: { plurals: plurals[locale] },
      },
      messages: {
        [locale]: require(`locale/${locale}/messages.po`).messages,
      },
    });

    return i18n;
  }, [locale]);

  React.useEffect(() => {
    process.env.GTM_ID && TagManager.initialize({ gtmId: config.gtmId });

    const handleRouteChangeComplete = () => {
      const event = {
        event: 'virtualPageView',
        virtualPagePath: location.pathname,
      };

      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push(event);
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, []);

  return (
    <I18nProvider i18n={i18nGlobal} key={locale}>
      <UIProvider>
        {/* <PageTransition> */}
        <SimpleReactLightbox>
          <Component {...pageProps} />
        </SimpleReactLightbox>
        {/* </PageTransition> */}
      </UIProvider>
    </I18nProvider>
  );
}
